





































import { goToVue3AppUrl } from '@/store/adapter';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class VFooter extends Vue {
  private goToVue3 = goToVue3AppUrl;
}
