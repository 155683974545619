import axios from 'axios';
import { AxiosResponse, AxiosError } from 'axios';
import buildUrl from 'build-url';
import Cookie from 'js-cookie';
import store from '@/store/index';
import { AccountState } from './account/types';

export const apiUrl = process.env.VUE_APP_API_URL as string;
export const staticUrl = process.env.VUE_APP_STATIC_URL as string;
export const vue3AppUrl = process.env.VUE_APP_VUE3_APP_URL as string;

export const goToVue3AppUrl = (path: string) => {
  window.location.href = `${vue3AppUrl}${path}`;
};

const camelToSnakeCase = (camel: string): string => {
  return camel.replace(/[\w]([A-Z])/g, (m) => m[0] + '_' + m[1]).toLowerCase();
};

const autoBuildUrl = (path: string, params: {[key: string]: string | boolean | number }): string => {
  const queryParams: {[key: string]: string } = {};
  // tslint:disable-next-line:forin
  for (const key in params) {
    if (params[key] === undefined) {
      params[key] = '';
    }
    if (params[key] !== '') {
      queryParams[camelToSnakeCase(key)] = params[key].toString();
    }
  }
  return buildUrl(apiUrl, { path, queryParams });
};


class Adapter {

public postRequest = (
  subPath: string,
  payload: any,
  sendToken?: boolean,
  options?: {},
  headers?: {}): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + subPath, payload, this.buildOptions(sendToken, options, headers),
    ).then((response: any) => {
      resolve(response.data);
    }).catch((error: AxiosError) => {
      reject(error.response);
    });
  });
}

public putRequest = (
  subPath: string,
  payload: any,
  sendToken?: boolean,
  options?: {},
  headers?: {}): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.put(apiUrl + subPath, payload, this.buildOptions(sendToken, options, headers),
    ).then((response: any) => {
      resolve(response.data);
    }).catch((error: AxiosError) => {
      reject(error.response);
    });
  });
}

public getRequest = (
    subPath: string,
    params: {[key: string]: string | boolean | number },
    sendToken?: boolean,
    options?: {},
    headers?: {}): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios.get(autoBuildUrl(subPath, params), this.buildOptions(sendToken, options, headers))
    .then((response: AxiosResponse) => {
      resolve(response.data);
    }).catch((error: AxiosError) => {
      reject(error.response);
    });
  });
}

private buildOptions = (sendToken?: boolean, options?: {}, extraHeaders?: {}) => {
  const headers: { [key: string]: string } = {
    'Content-type': 'application/json',
    ...extraHeaders,
  };
  if (sendToken) {
    headers.Authorization = 'Bearer ' + (store.state.account as AccountState).token;
  } else {
    options = {
      csrfmiddlewaretoken: Cookie.get('csrftoken'), ...options,
    };
  }
  return { withCredentials: false, headers, ...options };
}

}

export const adapter = new Adapter();
